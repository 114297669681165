import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import restClient from '../restClient';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import Input from '../components/Input';
import InputPassword from '../components/InputPassword';
import ButtonSubmit from '../components/ButtonSubmit';

const Register = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const navigate = useNavigate();
  const toggleVisibility = () => setVisible(!visible);

  const handleFocus = () => {
    if (!username) {
      toast('نام کاربری حداقل ۵ حرف و/یا عدد است.', {
        style: { maxWidth: 'fit-content', fontSize: '14px', },
        icon: <ExclamationCircleIcon className="w-6 h-6 text-[#5ed2fa]" />,
      }
      );
    }
  };

  const handleRegister = async () => {

    if (!username || !password || !confirmPassword) {
      toast.error('لطفاً همه ورودی‌ها را پر کنید.');
      return;
    }

    if (password !== confirmPassword) {
      toast.error('مقدارها با هم تطابق ندارند.');
      return;
    }
    setIsLoading(true);
    try {
      const response = await restClient.post('/register', { username, password, });
      toast.success(response.data);
      setTimeout(() => navigate('/'), 250);
    } catch (error) {
      toast.error(error.response?.data || 'ارتباط برقرار نیست.');
      console.error('Registration failed: ', error);
    }
    setIsLoading(false);
  };

  return (
    <div className="h-screen flex flex-col justify-center items-center">
      <div className="shadow-xl border border-slate-100 rounded-2xl m-2">
        <div className="flex flex-col sm:flex-row justify-center items-center">
          {/* Right side */}
          <img className="caret-transparent mr-0 w-64 h-64 rounded-tr-2xl sm:rounded-br-2xl" src="../../logo512.png" alt="Logo" />
          {/* Left side */}
          <main className="flex flex-col items-center">
            <h1 className="text-center text-xl mb-8 mt-4 text-neutral-700">صفحه ثبت‌نام</h1>
            <form onSubmit={(e) => { e.preventDefault(); handleRegister(); }} className="flex flex-col gap-4 mx-8 mb-6 sm:mr-4">
              <Input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                id="username"
                placeholder="نام‌ کاربری"
                onFocus={handleFocus}
                autoComplete="username"
              />
              <InputPassword
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                visible={visible}
                toggleVisibility={toggleVisibility}
                id="password"
                placeholder="کلمه‌ عبور"
                autoComplete="new-password"
              />
              <Input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                id="confirmPassword"
                placeholder="تکرار کلمه عبور"
                autoComplete="new-password"
              />
              <ButtonSubmit isLoading={isLoading} loadingText="در حال ثبت‌نام...">ثبت‌نام</ButtonSubmit>
              <Link className="caret-transparent text-center mt-2 text-sm text-neutral-700 hover:text-[#7BA7E6] hover:border-[#7BA7E6] active:text-[#6093D8] active:border-[#6093D8]" to="/login">ورود به برنامه</Link>
            </form>
          </main>
        </div>
      </div>
    </div>
  );
};

export default Register;
