import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useModal } from '../contexts/ModalContext';
import { ExclamationCircleIcon, PencilSquareIcon } from '@heroicons/react/24/outline';

import ModalForm from '../components/ModalForm';
import ButtonSubmit from '../components/ButtonSubmit';
import ButtonClose from '../components/ButtonClose';
import restClient from '../restClient';
import InputEmail from '../components/InputEmail';

const EmailModal = ({ id = 'addEmail', currentEmail }) => {
  const [newEmail, setNewEmail] = useState(currentEmail || '');
  const { closeModal } = useModal();
  const [isLoading, setIsLoading] = useState(false);
  const [change, setChange] = useState(!currentEmail);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!newEmail?.trim()) return

    setIsLoading(true);
    try {
      const response = await restClient.post('/email', { email: newEmail });
      toast.success(response.data);
      closeModal();
    } catch (error) {
      toast.error(error.response?.data || 'ارتباط برقرار نیست.');
      console.error('Add email failed:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalForm id={id} title="افزودن ایمیل" onSubmit={handleSubmit}>
      <div className="flex gap-2 items-start justify-center my-4">
        <ExclamationCircleIcon className="flex-shrink-0 flex-grow-0 w-7 text-[#5acef5]" />
        <p className="text-zinc-700 text-sm text-justify leading-5">
        ایمیل خود را ثبت کنید تا بتوانید کلمه عبور را بازیابی کنید.
        </p>
      </div>
      {!change && currentEmail && (
        <div className="flex items-center justify-between bg-gray-100 rounded-3xl px-4 p-3 mb-1.5">
          <button type="button" onClick={() => setChange(true)} className="text-[#5acef5] hover:text-[#4abbe8]">
            <PencilSquareIcon className="w-5 h-5" />
          </button>
          <div style={{ direction: 'ltr' }} className="text-zinc-500 text-sm font-sans overflow-hidden text-ellipsis whitespace-nowrap">
            {currentEmail}
          </div>
        </div>
      )}
      {change && (<InputEmail value={newEmail} onChange={(e) => setNewEmail(e.target.value)} />)}
      <ButtonSubmit isLoading={isLoading} loadingText="در حال افزودن...">تأیید</ButtonSubmit>
      <ButtonClose>انصراف</ButtonClose>
    </ModalForm>
  );
};

export default EmailModal;
