import React, { useContext, useEffect, useState } from "react";
import "../styles/sky.css";
import { DateObject } from "react-multi-date-picker";
import { AppContext } from "../contexts/AppContext";
import toast from "react-hot-toast";
import gregorian from "react-date-object/calendars/gregorian";
import gregorian_en from "react-date-object/locales/gregorian_en";
import { useModal } from "../contexts/ModalContext";
import CalendarModal from "../modals/CalendarModal";
import ConfirmModal from "../modals/ConfirmModal";
import ContextMenu from "./ContextMenu";

const useKeyboardHandler = (selectedNote, setSelectedNote, contextMenuNote, setContextMenuNote, setOnEdit, isModalOpen) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (isModalOpen()) return;

      if (contextMenuNote || selectedNote) {
        const actions = {
          'Escape': () => { setSelectedNote(null); setContextMenuNote(null); setOnEdit(false); },
        };
        actions[event.key]?.();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [selectedNote, setSelectedNote, contextMenuNote, setContextMenuNote, setOnEdit, isModalOpen]);
};

const Notes = () => {
  const { notes, getNotes, editNote, deleteNote, selectedNote, setSelectedNote, onEdit, setOnEdit, date, isPending } = useContext(AppContext);
  const { openModal, closeModal, isModalOpen } = useModal();
  const [calendarDate, setCalendarDate] = useState(new DateObject(date));
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const [contextMenuNote, setContextMenuNote] = useState(null);

  useEffect(() => setCalendarDate(new DateObject(date)), [date]);

  const handleNoteClick = (event, note) => {
    // Menu dimensions
    const menuWidth = 160;
    const menuHeight = 182;

    // Heights of fixed elements
    const headerHeight = 113;
    const footerHeight = 65;

    // Get the click position and scroll offsets
    const x = event.clientX;
    const y = event.clientY;
    const scrollX = window.scrollX || window.pageXOffset;
    const scrollY = window.scrollY || window.pageYOffset;

    // Calculate the new position ensuring the menu stays within the viewport
    const newX = Math.min(
      Math.max(x + scrollX - menuWidth, 0), // Prevent menu from going off the left
      window.innerWidth + scrollX - menuWidth // Prevent menu from going off the right
    );

    const newY = Math.min(
      Math.max(y + scrollY, headerHeight),
      window.innerHeight + scrollY - menuHeight - footerHeight // Prevent menu from going off the bottom
    );

    setMenuPosition({ x: newX, y: newY });
    setContextMenuNote(note);
  };


  const handleOutsideClick = (event) => {
    if (!event.target.closest('.note')) {
      setContextMenuNote(null);
    }
  };

  const handleScroll = () => setContextMenuNote(null);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    document.addEventListener("click", handleOutsideClick);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.removeEventListener("click", handleOutsideClick);
    }
  }, []);

  const handleDelete = async () => {
    const response = await deleteNote(selectedNote.id);
    if (response) toast.success(response.data);
    closeModal();
    await getNotes();
  };

  const handleToggleComplete = async (note) => {
    await editNote(note.id, { completed: !note.completed });
    await getNotes();
  };

  const handleDateChange = async (newDate) => {
    const formattedDate = newDate.convert(gregorian, gregorian_en).format("YYYY-MM-DD");
    const response = await editNote(selectedNote.id, { date: formattedDate });
    if (response) toast.success("یادداشت موکول شد.");
    closeModal();
    await getNotes();
  };

  useKeyboardHandler(selectedNote, setSelectedNote, contextMenuNote, setContextMenuNote, setOnEdit, isModalOpen);

  return (
    <div className={`${isPending ? "pointer-events-none" : ""}`} style={{ paddingBottom: onEdit ? `${Math.min(Math.max(selectedNote?.content.length || 0, 44), window.innerHeight * 0.25)}px` : "" }}>
      {isPending &&
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="w-32 h-32 border-4 border-blue-500 border-t-transparent border-solid rounded-full animate-spin" />
        </div>
      }
      {notes.map((note) => (
        <div key={note.id} className={`flex items-center mb-4 pr-4 md:pr-6 overflow-x-hidden ${isPending ? 'opacity-25' : ''}`}>
          <div className={`note flex-1 cursor-pointer max-w-fit flex items-center ${contextMenuNote?.id === note.id ? 'animate-pulse' : ''}`} onClick={(event) => handleNoteClick(event, note)}>
            <div className={`caret-transparent break-words bg-white text-lg border-4 rounded-t-md rounded-bl-2xl rounded-tl-2xl rounded-tr-2xl p-3 ${note.completed ? "line-through" : ""}`} style={{ maxWidth: "70vw", borderColor: note.color, whiteSpace: "pre-wrap" }}>
              {note.content}
            </div>
          </div>
          {contextMenuNote?.id === note.id &&
            <ContextMenu
              note={note}
              onDelete={() => { setOnEdit(false); setSelectedNote(note); openModal("deletionModal") }}
              onToggleComplete={() => { handleToggleComplete(note) }}
              onEdit={() => { setSelectedNote(note); setOnEdit(true) }}
              onOpenCalendar={() => { setOnEdit(false); setSelectedNote(note); openModal("calendarModal") }}
              position={menuPosition}
            />
          }
        </div>
      ))}
      <ConfirmModal id="deletionModal" onYes={handleDelete} question="پاک کنم؟" />
      <CalendarModal id="calendarModal" onChange={handleDateChange} date={calendarDate} />
    </div>
  );
};

export default Notes;
